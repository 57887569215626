import { render, staticRenderFns } from "./cpt-dataV-scrollTable.vue?vue&type=template&id=2102a396&scoped=true"
import script from "./cpt-dataV-scrollTable.vue?vue&type=script&lang=js"
export * from "./cpt-dataV-scrollTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2102a396",
  null
  
)

export default component.exports